import type { RawAxiosResponseHeaders, AxiosResponseHeaders } from 'axios';
import { ErrorResponse } from './interfaces';

interface AxiosTransportErrorOptions {
  message?: string;
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
  status: number;
  statusText: string;
  data?: ErrorResponse;
}

export class TransportError extends Error {
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
  status: number;
  statusText: string;
  data?: ErrorResponse;
  isTransportError = true;

  constructor(options: AxiosTransportErrorOptions) {
    // TODO: get message from options.data[default_path]
    super(options.message || options.statusText);

    this.headers = options.headers;
    this.status = options.status;
    this.statusText = options.statusText;
    this.data = options.data;
  }
}
