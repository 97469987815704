export const CHAR_NO_BREAK_SPACE = '\u00A0';
export const CHAR_EN_DASH = '\u2013';
export const MINUS = '-';
export const NUMBER_DECIMAL_SEPARATOR = '.';
export const MINUS_SYMBOLS = [MINUS, CHAR_EN_DASH];
export const DEFAULT_DECIMAL_SEPARATOR = '.';
export const DEFAULT_THOUSAND_SEPARATOR = ',';

export const POPOVER_Z_INDEX_BASE = 2001;
export const SIDE_PAGE_Z_INDEX_BASE = 2000;
export const MODAL_Z_INDEX_BASE = 2000;
