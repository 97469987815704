import axios, { isAxiosError } from 'axios';
import type { AxiosInstance, AxiosRequestConfig, AxiosHeaders } from 'axios';

import {
  Transport,
  TransportError,
  TransportRequestOptions,
  TransportResponse,
} from './interfaces';
import { AxiosTransportError } from './axios-transport-error';

// TODO: implement cancel request
// TODO: implement request progress

interface AxiosTransportOptions {
  baseUrl: string;
  getAccessToken: () => Promise<string | undefined>;
  authErrorHandler: (err: TransportError) => void;
}

interface AxiosTransportRequestOptions {
  sendAuthorizationHeaders?: boolean;
}

export class AxiosTransport implements Transport {
  public readonly axios: AxiosInstance;

  public readonly getAccessToken: () => Promise<string | undefined>;

  public readonly authErrorHandler: (err: TransportError) => void;

  constructor(options: AxiosTransportOptions) {
    this.axios = axios.create({
      baseURL: options.baseUrl,
      // TODO: support default headers
    });
    this.getAccessToken = options.getAccessToken;
    this.authErrorHandler = options.authErrorHandler;
  }

  async request<T = unknown>(
    config: AxiosRequestConfig,
    options?: AxiosTransportRequestOptions,
  ): Promise<TransportResponse<T>> {
    try {
      const requestConfig = {
        ...config,
      };

      if (options?.sendAuthorizationHeaders !== false) {
        const accessToken = await this.getAccessToken();
        if (accessToken) {
          requestConfig.headers = requestConfig.headers || {};
          requestConfig.headers.Authorization = `Bearer ${accessToken}`;
        }
      }

      const response = await this.axios<T>(requestConfig);

      // TODO: fix response.headers types, axios maintainers broke something https://github.com/axios/axios/issues/5034

      return {
        data: response.data,
        status: response.status,
        statusText: response.statusText,
        headers: (response.headers as AxiosHeaders).toJSON() as Record<string, string>,
      };
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const transportError = new AxiosTransportError({
          data: error.response.data,
          status: error.response.status,
          statusText: error.response.statusText,
          headers: (error.response.headers as AxiosHeaders).toJSON() as Record<string, string>,
        });

        if (error.response.status === 401) {
          this.authErrorHandler(transportError);
        }

        throw transportError;
      }

      // TODO: maybe convert any error to AxiosTransportError to make it type-safe?
      // Re-throw not axios error or connection error
      throw error;
    }
  }

  get<T = unknown>(url: string, options?: TransportRequestOptions): Promise<TransportResponse<T>> {
    return this.request<T>(
      {
        method: 'get',
        url,
        headers: options?.headers,
        params: options?.query,
        ...options,
      },
      {
        sendAuthorizationHeaders: options?.sendAuthorizationHeaders,
      },
    );
  }

  post<T = unknown>(
    url: string,
    data: unknown,
    options?: TransportRequestOptions,
  ): Promise<TransportResponse<T>> {
    return this.request<T>(
      {
        method: 'post',
        url,
        data,
        ...options,
      },
      {
        sendAuthorizationHeaders: options?.sendAuthorizationHeaders,
      },
    );
  }

  put<T = unknown>(
    url: string,
    data: unknown,
    options?: TransportRequestOptions,
  ): Promise<TransportResponse<T>> {
    return this.request<T>(
      {
        method: 'put',
        url,
        data,
        ...options,
      },
      {
        sendAuthorizationHeaders: options?.sendAuthorizationHeaders,
      },
    );
  }

  patch<T = unknown>(
    url: string,
    data: unknown,
    options?: TransportRequestOptions,
  ): Promise<TransportResponse<T>> {
    return this.request<T>(
      {
        method: 'patch',
        url,
        data,
        ...options,
      },
      {
        sendAuthorizationHeaders: options?.sendAuthorizationHeaders,
      },
    );
  }

  delete<T = unknown>(
    url: string,
    data?: unknown,
    options?: TransportRequestOptions,
  ): Promise<TransportResponse<T>> {
    return this.request<T>(
      {
        method: 'delete',
        url,
        data,
        ...options,
      },
      {
        sendAuthorizationHeaders: options?.sendAuthorizationHeaders,
      },
    );
  }
}
