import { nextTick, isRef } from 'vue';
import { createI18n as _createI18n, useI18n as _useI18n } from 'vue-i18n';
import type {
  NumberOptions,
  DefineNumberFormat,
  DefineDateTimeFormat,
  UseI18nOptions,
  I18n,
  Composer,
  I18nMode,
  VueI18n,
  Locale,
} from 'vue-i18n';
import { datetimeFormats, numberFormats } from './formats';
import enUS from './locales/en-us.json';

// TODO: find better workaround for DateTimeFormats and NumberFormats

type MessageSchema = typeof enUS;
type DateTimeFormats = typeof datetimeFormats;
type NumberFormats = typeof numberFormats;

declare module 'vue-i18n' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefineLocaleMessage extends MessageSchema {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefineDateTimeFormat extends DateTimeFormats {}

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefineNumberFormat extends NumberFormats {}
}

export const DEFAULT_LOCALE = 'en-US';

export const LOCALES = [
  { code: 'en-US', language: 'English' },
  { code: 'pt-BR', language: 'Português' },
  { code: 'es-MX', language: 'Español' },
  { code: 'ja-JP', language: '日本語' },
];

function isComposer(instance: VueI18n | Composer, mode: I18nMode): instance is Composer {
  return mode === 'composition' && isRef(instance.locale);
}

function setLocale(i18n: I18n, locale: Locale): void {
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale;
  } else {
    i18n.global.locale = locale;
  }
}

let i18n: I18n;

export function createI18n() {
  i18n = _createI18n<[MessageSchema], string, false>({
    legacy: false,
    locale: DEFAULT_LOCALE,
    fallbackLocale: DEFAULT_LOCALE,
    messages: {
      'en-US': enUS,
    },
    datetimeFormats: {
      'en-US': datetimeFormats as DefineDateTimeFormat,
      'pt-BR': datetimeFormats as DefineDateTimeFormat,
      'es-MX': datetimeFormats as DefineDateTimeFormat,
      'ja-JP': datetimeFormats as DefineDateTimeFormat,
    },
    numberFormats: {
      'en-US': numberFormats as DefineNumberFormat,
      'pt-BR': numberFormats as DefineNumberFormat,
      'es-MX': numberFormats as DefineNumberFormat,
      'ja-JP': numberFormats as DefineNumberFormat,
    },
  });

  return i18n;
}

// We need correct `Key` argument in `NumberOptions` generic type to have same types as in `n` function
// This type conversion based on vue-i18n source code
type Key = keyof {
  [K in keyof DefineNumberFormat as string extends K
    ? never
    : number extends K
      ? never
      : K]: DefineNumberFormat[K];
};

export function useI18n(options?: UseI18nOptions): Composer & {
  nSafe: (value: number | null, format: NumberOptions<Key> | Key, fallback?: string) => string;
  nMoney: (value: number | null, currency: string | null, fallback?: string) => string;
} {
  const composer = _useI18n(options);

  function nSafe(value: number | null, format: NumberOptions<Key> | Key, fallback = ''): string {
    return typeof value === 'number' ? composer.n(value, format) : fallback;
  }

  function nMoney(value: number | null, currency: string | null, fallback = ''): string {
    return nSafe(
      value,
      typeof currency === 'string' && currency.length === 3
        ? { key: 'money', currency }
        : { key: 'pretty', minimumFractionDigits: 2, maximumFractionDigits: 2 },
      fallback,
    );
  }

  return { ...composer, nSafe, nMoney };
}

async function loadLocaleMessages(locale: string) {
  const messages = await import(`./locales/${locale.toLowerCase()}.json`).then(
    (r: any) => r.default || r,
  );

  i18n.global.setLocaleMessage(locale, messages);

  return nextTick();
}

export async function setI18nLanguage(locale: string) {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/getCanonicalLocales
  // @ts-expect-error https://github.com/microsoft/TypeScript/issues/29129
  const [canonicalLocale] = Intl.getCanonicalLocales(locale);

  // Load unavailable but supported locale
  if (
    !i18n.global.availableLocales.includes(canonicalLocale) &&
    LOCALES.find(({ code }) => code === canonicalLocale)
  ) {
    await loadLocaleMessages(canonicalLocale);
  }

  setLocale(i18n, locale);
}
