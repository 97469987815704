import { RouterView } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import { authGuard, notAuthGuard, createFeatureFlagGuard } from '../guards';
import { FeatureFlag } from '../../services/unleash';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('../../layouts/default-layout.vue'),
    meta: {
      guards: [authGuard],
    },
    children: [
      {
        path: '',
        redirect: '/inventory',
      },
      {
        path: '/replenishment',
        name: 'replenishment',
        component: () => import('../../views/replenishment/replenishment-index.vue'),
        redirect: '/replenishment/overview',
        children: [
          {
            path: 'overview',
            component: () => import('../../views/replenishment/replenishment-overview.vue'),
          },
          {
            path: 'store',
            redirect: (to) => ({
              path: '/replenishment/locations',
              query: to.query,
            }),
          },
          {
            path: 'locations',
            component: () => import('../../views/replenishment/replenishment-locations.vue'),
          },
          {
            path: 'products',
            component: () => import('../../views/replenishment/replenishment-products.vue'),
          },
          {
            path: 'inventories',
            component: () => import('../../views/replenishment/replenishment-inventories.vue'),
            meta: {
              guards: [createFeatureFlagGuard(FeatureFlag.ReplenishmentInventories)],
            },
          },
          // TODO: remove this fallback redirect in 2024
          {
            path: 'store',
            redirect: (to) => ({
              path: '/replenishment/locations',
              query: to.query,
            }),
          },
          {
            path: 'product',
            redirect: (to) => ({
              path: '/replenishment/products',
              query: to.query,
            }),
          },
        ],
      },
      {
        path: '/analytics',
        name: 'analytics',
        component: () => import('../../views/analytics/analytics-index.vue'),
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.Analytics)],
        },
        children: [
          {
            path: 'dashboards/:id',
            name: 'analytics-dashboard',
            component: () => import('../../views/analytics/analytics-dashboard.vue'),
          },
        ],
      },
      {
        path: '/analytics-embedded',
        name: 'analytics-embedded',
        component: () => import('../../views/analytics-embedded/analytics-index.vue'),
        children: [
          {
            path: 'dashboards/:id',
            name: 'analytics-embedded-dashboard',
            component: () => import('../../views/analytics-embedded/analytics-dashboard.vue'),
          },
        ],
      },
      {
        path: '/inventory',
        component: RouterView,
        children: [
          {
            path: '',
            component: () => import('../../views/inventory/inventory-index.vue'),
            redirect: '/inventory/transactions',
            children: [
              {
                path: 'transactions',
                component: () => import('../../views/inventory/inventory-transactions.vue'),
                meta: {
                  guards: [
                    createFeatureFlagGuard(
                      FeatureFlag.InventoryTransactions,
                      '/inventory/location',
                    ),
                  ],
                },
              },
              {
                path: 'location',
                component: () => import('../../views/inventory/inventory-location.vue'),
              },
              {
                path: 'sku',
                component: () => import('../../views/inventory/inventory-sku.vue'),
              },
              // TODO: remove this fallback redirect in 2024
              {
                path: 'sku-location',
                redirect: (to) => ({
                  path: '/inventory/inventories',
                  query: to.query,
                }),
              },
              {
                path: 'inventories',
                component: () => import('../../views/inventory/inventory-inventories.vue'),
              },
            ],
          },
          {
            path: 'inventories/:id',
            component: () => import('../../modules/sku-chart/views/sku-chart.vue'),
            props: {
              breadcrumbs: ['inventory', 'inventories'],
            },
          },
        ],
      },
      {
        path: '/store-transfer',
        component: () => import('../../views/store-transfer/store-transfer-index.vue'),
        redirect: '/store-transfer/recommendations',
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.StoreTransfer)],
        },
        children: [
          {
            path: 'recommendations',
            component: () =>
              import('../../views/store-transfer/store-transfer-recommendations.vue'),
            redirect: '/store-transfer/recommendations/trips',
            children: [
              {
                path: 'trips',
                component: () =>
                  import('../../views/store-transfer/store-transfer-recommendations-trips.vue'),
              },
              {
                path: 'products',
                component: () =>
                  import('../../views/store-transfer/store-transfer-recommendations-products.vue'),
              },
            ],
          },
          {
            path: 'transfers',
            component: () => import('../../views/store-transfer/store-transfer-transfers.vue'),
          },
        ],
      },
      {
        path: '/allocations',
        component: RouterView,
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.Allocations)],
        },
        children: [
          {
            path: '',
            component: () => import('../../modules/allocations/views/allocations-index.vue'),
            children: [],
          },
          {
            path: 'upload',
            component: () => import('../../modules/allocations/views/allocations-upload.vue'),
          },
        ],
      },
      {
        path: '/special-events',
        component: RouterView,
        meta: {
          guards: [createFeatureFlagGuard(FeatureFlag.SpecialEventsNew)],
        },
        children: [
          {
            path: '',
            redirect: '/special-events/pending',
            component: () => import('../../modules/special-events/views/special-events-index.vue'),
            children: [
              {
                path: 'pending',
                component: () =>
                  import('../../modules/special-events/views/special-events-pending.vue'),
              },
              {
                path: 'upcoming',
                component: () =>
                  import('../../modules/special-events/views/special-events-upcoming.vue'),
              },
              {
                path: 'approved',
                component: () =>
                  import('../../modules/special-events/views/special-events-approved.vue'),
              },
            ],
          },
          {
            path: ':id',
            component: () => import('../../modules/special-events/views/special-event.vue'),
          },
          {
            path: 'upload',
            component: () => import('../../modules/special-events/views/special-events-upload.vue'),
          },
        ],
      },
      {
        path: '/sku-chart/:id',
        component: () => import('../../modules/sku-chart/views/sku-chart.vue'),
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      guards: [notAuthGuard],
    },
    component: () => import('../../views/auth-page.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../../views/not-found.vue'),
  },
];
