import { PaginationQuery } from './interfaces/shared';
import { AxiosTransport } from './transport';
import { KeycloakAuth } from './auth';
import type { Transport } from './transport';
import {
  ReplenishmentKpis,
  PaginatedResponse,
  Location,
  Inventory,
  InventoryDetails,
  ProductDetails,
  Product,
  ProductWithDetails,
  ReplenishmentPickingList,
  ReplenishmentOverview,
  InsufficientReplenishmentSummary,
  InsufficientReplenishmentOverviewItem,
  ProductSkuDetails,
  InventorySkuChartOverviewItem,
  InventorySkuChartSummary,
  Upload,
  LocationKpis,
  SkuKpis,
  InventoryKpis,
  AvoidReplenishmentPayload,
  AvoidReplenishmentResponse,
  MeilisearchIndexesResponse,
  ProductSkuDetailsInLocation,
  MeilisearchInventory,
  MeilisearchSku,
  MeilisearchLocation,
  MissingItem,
  ReplenishmentProduct,
} from './interfaces';
import { MeiliSearchService } from './meilisearch-service';
import { StoreTransfer } from './store-transfer';
import { Superset } from './superset';
import { Replenishment } from './replenishment';

export interface ApiServiceOptions {
  transport?: Transport;
  baseUrl: string;
  meilisearchHost: string;
  keycloak: {
    url: string;
    realm: string;
    clientId: string;
    enableLogging?: boolean;
    silentCheckSsoRedirectUri: string;
  };
}

type AccountSettings = {
  name: string;
  locale: string;
  currency: string;
  wh_replenishment_active: boolean;
  timezone_name: string;
  timezone_offset_in_hours: number;
  custom_data_schema?: {
    [scope: string]: [{ key: string; type: 'string' | 'float' | 'boolean' | 'datetime' }];
  };
};

export class ApiService {
  private readonly transport: Transport;
  readonly auth: KeycloakAuth;
  readonly meilisearch: MeiliSearchService;
  readonly storeTransfer: StoreTransfer;
  readonly superset: Superset;
  readonly replenishment: Replenishment;

  constructor(options: ApiServiceOptions) {
    this.transport =
      options?.transport ||
      new AxiosTransport({
        baseUrl: options.baseUrl,
        getAccessToken: () => {
          return this.auth.getToken();
        },
        authErrorHandler: () => {
          this.auth.logout();
        },
      });

    this.auth = new KeycloakAuth({ ...options.keycloak, transport: this.transport });
    this.meilisearch = new MeiliSearchService({
      auth: this.auth,
      transport: this.transport,
      host: options.meilisearchHost,
    });
    this.storeTransfer = new StoreTransfer({
      transport: this.transport,
    });
    this.superset = new Superset({
      transport: this.transport,
    });
    this.replenishment = new Replenishment({
      transport: this.transport,
    });
  }

  getSystemServicesStatus() {
    return this.transport.get<{ Meilisearch: boolean }>('v1/system_services');
  }

  getAccountSettings() {
    return this.transport.get<AccountSettings>('v1/account');
  }

  getMeilisearchIndexes() {
    return this.transport.get<{ data: MeilisearchIndexesResponse }>('/v1/meilisearch/indexes');
  }

  getSkuKpis() {
    return this.transport.get<SkuKpis>('/v1/skus/kpis');
  }

  getLocationKpis() {
    return this.transport.get<LocationKpis>('/v1/locations/kpis');
  }

  getInventoryKpis() {
    return this.transport.get<InventoryKpis>('/v1/inventories/kpis');
  }

  getReplenishmentKpis(locationType?: 'store' | 'warehouse') {
    return this.transport.get<ReplenishmentKpis>('/v1/replenishments/kpis', {
      query: { location_type: locationType },
    });
  }

  getProducts(
    query?: PaginationQuery & { location_type?: 'store' | 'warehouse'; location_id?: string },
  ) {
    return this.transport.get<PaginatedResponse<ReplenishmentProduct>>('/v1/products', {
      query: { ...query },
    });
  }

  getProductsByIds(
    ids: string[],
    params?: { locationType?: 'store' | 'warehouse'; locationId?: string },
  ) {
    return this.transport.post<{ data: Array<ReplenishmentProduct | MissingItem> }>(
      '/v1/products/by_ids',
      {
        ids,
        location_type: params?.locationType,
        location_id: params?.locationId,
      },
    );
  }

  getProduct(id: string, locationType?: 'store' | 'warehouse') {
    return this.transport.get<{ data: ProductWithDetails }>(`/v1/products/${id}`, {
      query: { location_type: locationType },
    });
  }

  getProductInventoryIdsListedByLocation(productId: string, locationType?: 'store' | 'warehouse') {
    return this.transport.get<Record<string, string[]>>(
      '/v1/products/inventories_list_by_location',
      {
        query: { id: productId, location_type: locationType },
      },
    );
  }

  getLocationsByIds(ids: string[]) {
    return this.transport.post<{ data: Array<MeilisearchLocation | MissingItem> }>(
      '/v1/locations/by_ids',
      {
        ids,
      },
    );
  }

  getLocation(id: string) {
    return this.transport.get<Location>(`/v1/locations/${id}`);
  }

  getLocations(query?: PaginationQuery) {
    return this.transport.get<PaginatedResponse<MeilisearchLocation>>('/v1/locations', {
      query: { ...query },
    });
  }

  getInventoriesByIds(ids: string[]) {
    return this.transport.post<{ data: Array<MeilisearchInventory | MissingItem> }>(
      '/v1/inventories/by_ids',
      {
        ids,
      },
    );
  }

  getInventories(query?: PaginationQuery) {
    return this.transport.get<PaginatedResponse<MeilisearchInventory>>('/v1/inventories', {
      query: { ...query },
    });
  }

  getInventory(id: string) {
    return this.transport.get<Inventory>(`/v1/inventories/${id}`);
  }

  getReplenishmentProductsByLocation(
    locationId: string,
    query?: PaginationQuery & { external_id?: string },
  ) {
    return this.transport.get<PaginatedResponse<Product>>('/v1/replenishments/products_by_store', {
      query: { location_id: locationId, ...query },
    });
  }

  getProductsDetails(productIds: string[], locationType?: 'store' | 'warehouse') {
    return this.transport.get<Record<string, ProductDetails>>('/v1/products/details', {
      query: { ids: productIds, location_type: locationType },
    });
  }

  getProductSkuDetails(productId: string, locationType?: 'store' | 'warehouse') {
    return this.transport.get<Record<string, ProductSkuDetails>>('/v1/products/sku_details', {
      query: { id: productId, location_type: locationType },
    });
  }

  getProductSkuDetailsInLocation(productId: string, locationId: string) {
    return this.transport.get<{ data: ProductSkuDetailsInLocation[] }>(
      `v1/products/sku_details_by_store/${productId}/${locationId}`,
    );
  }

  getInventoryDetails(id: string) {
    return this.transport.get<InventoryDetails>('/v1/inventories/details', {
      query: { id },
    });
  }

  getReplenishmentPickingList() {
    return this.transport.post<ReplenishmentPickingList>(
      '/v1/replenishments/export_picking_list',
      {},
    );
  }

  getReplenishmentPickingListCsv() {
    // TODO: bad idea https://github.com/axios/axios/issues/5891
    return this.transport.get<Blob>(`/v1/replenishments/picking_list.csv`, {
      responseType: 'blob',
    });
  }

  getReplenishmentPickingListJson() {
    return this.transport.get<{ data: Record<string, string | number | boolean> }>(
      `/v1/replenishments/picking_list.json`,
    );
  }

  getReplenishmentOverview(locationType: 'store' | 'warehouse') {
    return this.transport.get<ReplenishmentOverview>('/v1/replenishments/overview', {
      query: {
        location_type: locationType,
      },
    });
  }

  getInsufficientReplenishmentSummary(inventoryId: string) {
    return this.transport.get<{ data: InsufficientReplenishmentSummary }>(
      `/v1/replenishments/insufficient_replenishment/card/${inventoryId}`,
    );
  }

  getInsufficientReplenishmentOverview(inventoryId: string) {
    return this.transport.get<InsufficientReplenishmentOverviewItem[]>(
      `/v1/replenishments/insufficient_replenishment/overview/${inventoryId}`,
    );
  }

  getInventorySkuChartSummary(inventoryId: string) {
    return this.transport.get<InventorySkuChartSummary>(
      `/v1/inventories/sku_chart/card/${inventoryId}`,
    );
  }

  getInventorySkuChartOverview(
    inventoryId: string,
    query?: { start_date: string; end_date: string },
  ) {
    return this.transport.get<InventorySkuChartOverviewItem[]>(
      `/v1/inventories/sku_chart/overview/${inventoryId}`,
      {
        query,
      },
    );
  }

  getSkuByIds(ids: string[]) {
    return this.transport.post<{ data: Array<MeilisearchSku | MissingItem> }>('v1/skus/by_ids', {
      ids,
    });
  }

  getSkus(query?: PaginationQuery) {
    return this.transport.get<PaginatedResponse<MeilisearchSku>>('/v1/skus', {
      query: { ...query },
    });
  }

  getUploads(query?: PaginationQuery) {
    return this.transport.get<Upload[]>('/v1/uploads', {
      query,
    });
  }

  avoidReplenishment(payload: AvoidReplenishmentPayload) {
    const { type, ids } = payload;
    return this.transport.put<AvoidReplenishmentResponse>(
      `/v1/${type}/avoid_replenishment`,
      { ids: JSON.stringify(ids) }, // TODO: set normal array when backend will be fixed
    );
  }
}
