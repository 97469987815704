import { defineStore } from 'pinia';
import { z } from 'zod';
import type { ZodRawShape } from 'zod';
import { createMigratingPersistedState } from './create-migrating-persisted-state';

enum Columns {
  Name = 'name',
  ExternalId = 'external_id',
  LocationType = 'location_type',
  Description = 'description',
  Region = 'region',
  City = 'city',
  Brands = 'brands',
  Classifications = 'classifications',
  AtSite = 'at_site',
  InTransit = 'in_transit',
  Reserved = 'reserved',
  NumOfProducts = 'num_of_products',
  NumOfSkus = 'num_of_skus',
  InventoryCost = 'inventory_cost',
  InventoryPrice = 'inventory_price',
  AvoidReplenishment = 'avoid_replenishment',
  CreatedAt = 'created_at',
}

interface State {
  columnsVisibility: Record<string, boolean>;
  columnsOrder: string[];
  sorting: {
    sortBy: string | null;
    sortOrder: 'asc' | 'desc';
  };
  appliedFilters: {
    location_type: string[];
    region: string[];
    city: string[];
    avoid_replenishment: boolean | null;
  };

  readonly __v: number;
}

function getState(): State {
  return {
    columnsVisibility: {
      [Columns.Name]: true,
      [Columns.ExternalId]: false,
      [Columns.LocationType]: true,
      [Columns.Description]: false,
      [Columns.Region]: false,
      [Columns.City]: true,
      [Columns.Brands]: true,
      [Columns.Classifications]: true,
      [Columns.AtSite]: true,
      [Columns.InTransit]: true,
      [Columns.Reserved]: true,
      [Columns.NumOfProducts]: false,
      [Columns.NumOfSkus]: false,
      [Columns.InventoryCost]: false,
      [Columns.InventoryPrice]: false,
      [Columns.AvoidReplenishment]: true,
      [Columns.CreatedAt]: false,
    },
    columnsOrder: [
      Columns.Name,
      Columns.ExternalId,
      Columns.LocationType,
      Columns.Description,
      Columns.Region,
      Columns.City,
      Columns.Brands,
      Columns.Classifications,
      Columns.AtSite,
      Columns.InTransit,
      Columns.Reserved,
      Columns.NumOfProducts,
      Columns.NumOfSkus,
      Columns.InventoryCost,
      Columns.InventoryPrice,
      Columns.AvoidReplenishment,
      Columns.CreatedAt,
    ],
    sorting: {
      sortBy: null,
      sortOrder: 'asc',
    },
    appliedFilters: {
      location_type: [],
      region: [],
      city: [],
      avoid_replenishment: null,
    },
    __v: 0,
  };
}

function getSchema() {
  const defaultState = getState();

  return z.object({
    columnsVisibility: z.object(
      Object.keys(defaultState.columnsVisibility).reduce<ZodRawShape>((acc, key) => {
        acc[key] = z.boolean().catch(defaultState.columnsVisibility[key]);

        return acc;
      }, {}),
    ),
    columnsOrder: z.array(z.nativeEnum(Columns)).catch(defaultState.columnsOrder as Columns[]),
    sorting: z.object({
      sortBy: z.string().nullable().catch(defaultState.sorting.sortBy),
      sortOrder: z.enum(['asc', 'desc']).catch(defaultState.sorting.sortOrder),
    }),
  });
}

export const useInventoryLocationsPageStore = defineStore('inventory-locations-page', {
  state: getState,
  persist: createMigratingPersistedState({
    migrations: [
      // v1 - save data from the old app setting store
      function v1(state) {
        const oldState = window.localStorage.getItem('onebeat-app:app-settings');

        if (!oldState) {
          return {};
        }

        const parsedState = JSON.parse(oldState);

        const { visibleColumns, sorting } = parsedState.pages.inventoryLocations;

        return {
          ...state,
          sorting,
          columnsVisibility: {
            [Columns.Name]: visibleColumns.includes(Columns.Name),
            [Columns.ExternalId]: visibleColumns.includes(Columns.ExternalId),
            [Columns.LocationType]: visibleColumns.includes(Columns.LocationType),
            [Columns.Description]: visibleColumns.includes(Columns.Description),
            [Columns.Region]: visibleColumns.includes(Columns.Region),
            [Columns.City]: visibleColumns.includes(Columns.City),
            [Columns.Brands]: visibleColumns.includes(Columns.Brands),
            [Columns.Classifications]: visibleColumns.includes(Columns.Classifications),
            [Columns.AtSite]: visibleColumns.includes(Columns.AtSite),
            [Columns.InTransit]: visibleColumns.includes(Columns.InTransit),
            [Columns.Reserved]: visibleColumns.includes(Columns.Reserved),
            [Columns.NumOfProducts]: visibleColumns.includes(Columns.NumOfProducts),
            [Columns.NumOfSkus]: visibleColumns.includes(Columns.NumOfSkus),
            [Columns.InventoryCost]: visibleColumns.includes(Columns.InventoryCost),
            [Columns.InventoryPrice]: visibleColumns.includes(Columns.InventoryPrice),
            [Columns.AvoidReplenishment]: visibleColumns.includes(Columns.AvoidReplenishment),
            [Columns.CreatedAt]: visibleColumns.includes(Columns.CreatedAt),
          },
        };
      },
    ],
    schema: getSchema,
  }),
});
