import { MeiliSearch as MeiliSearchClient } from 'meilisearch';
import jwtDecode from 'jwt-decode';
import type { AbstractAuth, AbstractTransport } from './interfaces';

interface MeilisearchTokenResponse {
  token: string;
}

interface MeiliSearchOptions {
  host: string;
  transport: AbstractTransport;
  auth: AbstractAuth;
}

export enum MeilisearchIndexes {
  Location = 'Location',
  Sku = 'Sku',
  Inventory = 'Inventory',
  Replenishment = 'Replenishment',
  ReplenishmentInventory = 'Inventory-Replenishment',
  SpecialEvent = 'SpecialEvent',
  SpecialEventInventory = 'SpecialEventInventory',
  Department = 'Department',
  Region = 'Region',
  City = 'City',
  Brand = 'Brand',
  Classification = 'Classification',
  Season = 'Season',
  Size = 'Size',
  Style = 'Style',
  Category = 'Category',
  Market = 'Market',
  Color = 'Color',
  Product = 'Product',
}

export class MeiliSearch {
  protected readonly host: string;
  protected readonly transport: AbstractTransport;
  protected readonly auth: AbstractAuth;

  protected tokenExpiresAt = 0;
  protected minTokenValidity = 1000 * 30;
  protected clientPromise?: Promise<MeiliSearchClient>;

  protected client?: MeiliSearchClient;

  constructor(options: MeiliSearchOptions) {
    this.host = options.host;
    this.transport = options.transport;
    this.auth = options.auth;

    this.auth.onStateChanged(({ authenticated }) => {
      if (!authenticated) {
        this.client = undefined;
        this.tokenExpiresAt = 0;
      }
    });
  }

  async getClient(): Promise<MeiliSearchClient> {
    if (this.clientPromise) {
      return this.clientPromise;
    }

    if (this.client && this.tokenExpiresAt - Date.now() > this.minTokenValidity) {
      return this.client;
    }

    this.clientPromise = this.createClient();

    this.clientPromise.then(() => {
      this.clientPromise = undefined;
    });

    return this.clientPromise;
  }

  protected async checkAuth() {
    await this.auth.isReady();

    if (!this.auth.isAuthenticated) {
      throw new Error('Cannot create MeiliSearch client due to user is not authenticated');
    }
  }

  protected async getToken(): Promise<string> {
    const { data } = await this.transport.post<MeilisearchTokenResponse>('/v1/meilisearch', {});

    return data.token;
  }

  protected async createClient(): Promise<MeiliSearchClient> {
    await this.checkAuth();

    const token = await this.getToken();

    this.client = new MeiliSearchClient({
      host: this.host,
      apiKey: token,
    });

    const { exp } = jwtDecode<{ exp: number }>(token);
    this.tokenExpiresAt = exp * 1000;

    return this.client;
  }
}
