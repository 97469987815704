import { KeycloakAuth } from './keycloak-auth';
import { AxiosTransport } from './axios-transport';
import type { KeycloakAuthOptions } from './keycloak-auth';
import { MeiliSearch } from './meilisearch';
import type { AbstractApiService, AbstractAuth, AbstractTransport } from './interfaces';

export interface ApiServiceOptions {
  baseUrl: string;
  meilisearchHost: string;
  keycloak: Omit<KeycloakAuthOptions, 'transport'>;
}

export class ApiService implements AbstractApiService {
  readonly transport: AbstractTransport;
  readonly auth: AbstractAuth;
  readonly meiliSearch: MeiliSearch;

  constructor(options: ApiServiceOptions) {
    this.transport = new AxiosTransport({
      baseUrl: options.baseUrl,
      getAccessToken: () => {
        return this.auth.getToken();
      },
      authErrorHandler: () => {
        this.auth.logout();
      },
    });
    this.auth = new KeycloakAuth({ ...options.keycloak, transport: this.transport });
    this.meiliSearch = new MeiliSearch({
      host: options.meilisearchHost,
      auth: this.auth,
      transport: this.transport,
    });
  }
}
