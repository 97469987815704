import Keycloak from 'keycloak-js';
import mitt from 'mitt';
import type { Emitter } from 'mitt';
import { useApi } from '../api';
import { TransportResponse } from './interfaces';
import type {
  AbstractAuth,
  AbstractTransport,
  AuthEvents,
  UserInfo,
  AuthStateChangeHandler,
  UserInfoUpdatePayload,
} from './interfaces';

export interface KeycloakAuthOptions {
  url: string;
  realm: string;
  clientId: string;
  enableLogging?: boolean;
  silentCheckSsoRedirectUri: string;
  logoutRedirectUri: string;
  transport: AbstractTransport;
}

export class KeycloakAuth implements AbstractAuth {
  private readonly emitter: Emitter<AuthEvents>;

  private readonly readyPromise: Promise<void>;
  private readonly transport: AbstractTransport;
  private readonly logoutRedirectUri: string;
  private readonly keycloak: Keycloak;

  constructor(options: KeycloakAuthOptions) {
    this.emitter = mitt<AuthEvents>();
    this.transport = options.transport;
    this.logoutRedirectUri = options.logoutRedirectUri;

    this.keycloak = new Keycloak({
      url: options.url,
      realm: options.realm,
      clientId: options.clientId,
    });

    let readyResolver: () => void;
    this.readyPromise = new Promise((resolve) => {
      readyResolver = resolve;
    });

    this.keycloak.onTokenExpired = () => this.keycloak.updateToken(0);
    this.keycloak.onAuthSuccess = () => {
      this.emitStateChange();
    };
    this.keycloak.onAuthLogout = () => {
      this.emitStateChange();
    };
    this.keycloak.onAuthRefreshError = () => {
      this.keycloak.clearToken();
    };
    this.keycloak.onAuthError = () => {
      this.keycloak.clearToken();
    };
    this.keycloak.onReady = async () => {
      readyResolver();
    };

    // TODO: uncomment after dropping the old Api service
    // this.keycloak.init({
    //   checkLoginIframe: false,
    //   enableLogging: options.enableLogging,
    //   onLoad: 'check-sso',
    //   silentCheckSsoRedirectUri: options.silentCheckSsoRedirectUri,
    // });
  }

  private emitStateChange(): void {
    this.emitter.emit('stateChange', {
      authenticated: this.isAuthenticated(),
    });
  }

  async getToken() {
    const api = useApi();
    return api.auth.getToken();
    // await this.keycloak.updateToken(30);
    // return this.keycloak.token;
  }

  async logout(redirectUri?: string) {
    await this.keycloak.logout({
      redirectUri:
        redirectUri ??
        this.keycloak.createLoginUrl({
          redirectUri: this.logoutRedirectUri,
        }),
    });
  }

  async login(redirectUri?: string) {
    await this.keycloak.login({
      prompt: 'login',
      redirectUri,
    });
  }

  isAuthenticated(): boolean {
    return !!this.keycloak.authenticated;
  }

  async getUserInfo(): Promise<UserInfo | undefined> {
    const info = await this.keycloak.loadUserInfo();
    return info as UserInfo;
  }

  updateUserInfo(updatedUserInfo: UserInfoUpdatePayload): Promise<TransportResponse<void>> {
    const accountUrl = this.keycloak.createAccountUrl().split('?')[0];
    return this.transport.post<void>(accountUrl, updatedUserInfo);
  }

  changeUserPassword() {
    this.keycloak.login({ action: 'UPDATE_PASSWORD' });
  }

  async isReady() {
    return this.readyPromise;
  }

  onStateChanged(handler: AuthStateChangeHandler): () => void {
    this.emitter.on('stateChange', handler);
    return () => this.emitter.off('stateChange', handler);
  }
}
