<script setup lang="ts">
import { onBeforeMount, ref, computed } from 'vue';
import { useRoute, useRouter, RouterView } from 'vue-router';
import { until } from '@vueuse/core';
import { useHead } from '@unhead/vue';
import { captureException } from '@sentry/vue';
import catError from '../assets/cat-table-error.svg';
import { useApi } from '../services/api';
import type { AuthStateChangeEvent } from '../services/api';
import { getHeap } from '../services/heap';
import { setI18nLanguage, LOCALES, DEFAULT_LOCALE, useI18n } from '../services/i18n';
import { useFlagsStatus, useUnleashClient } from '../services/unleash';
import {
  useUserStore,
  useDataStore,
  useI18nStore,
  useAccountStore,
  useSystemServicesStatusStore,
} from '../store';
import { runGuards } from '../router';
import {
  ObConfigProvider,
  ObToasts,
  ObSpinner,
  ObConfirmDialog,
  ObErrorState,
  ObButton,
} from '../ui-kit';
import type { ObConfig } from '../ui-kit';
import { useAppReady } from './use-app-ready';
import { useSW } from './use-sw';

if (!__DEV__) {
  useSW();
}

const router = useRouter();
const route = useRoute();
const { emit: emitAppReady } = useAppReady();
const userStore = useUserStore();
const accountStore = useAccountStore();
const dataStore = useDataStore();
const i18nStore = useI18nStore();
const systemServicesStatusStore = useSystemServicesStatusStore();
const { t, n, d } = useI18n();
const api = useApi();
const unleashClient = useUnleashClient();
const { flagsReady } = useFlagsStatus();

const initializing = ref(true);
const error = ref(false);

async function reRunCurrentRouteGuards() {
  const result = await runGuards(route, route, { api });

  if (result === false) {
    await router.push('/');
  } else if (typeof result !== 'undefined' && result !== true) {
    await router.push(result);
  }
}

async function handleAuthStateChange(
  event: AuthStateChangeEvent,
  initial?: boolean,
): Promise<void> {
  const heap = getHeap();

  if (event.authenticated) {
    await Promise.all([userStore.fetch(), accountStore.fetch(), systemServicesStatusStore.fetch()]);

    dataStore.fetch().catch((error) => {
      // TODO: Handle error
      console.error(error);
    });

    if (heap) {
      heap.resetIdentity();
      heap.identify(userStore.email);
      heap.addUserProperties({
        name: userStore.name,
        email: userStore.email,
        account_id: userStore.account_id,
        account_name: accountStore.name,
      });
    }

    unleashClient.setContextField('userId', userStore.id);
    unleashClient.setContextField('accountId', userStore.account_id);
  } else {
    userStore.clear();
    accountStore.clear();
    dataStore.clear();

    if (heap) {
      heap.resetIdentity();
    }

    unleashClient.setContextField('userId', '');
    unleashClient.setContextField('accountId', '');
  }

  // Simply check if user can stay on current route
  if (!initial) {
    reRunCurrentRouteGuards();
    // TODO: handle possible error
  }
}

const uiConfig = computed<ObConfig>(() => {
  const config: ObConfig = {
    numberFormat: {
      decimalSeparator: i18nStore.decimalSeparator,
      thousandSeparator: i18nStore.thousandSeparator,
    },
    i18n: {
      BatchActionsMenu: {
        labelSelectedItems(count) {
          return t('ui.BatchActionsMenu.labelSelectedItems', count);
        },
      },
      TablePagination: {
        labelRowsPerPage: t('ui.TablePagination.labelRowsPerPage'),
        labelDisplayedRows(currentPageStart, currentPageEnd, total) {
          return t('ui.TablePagination.labelDisplayedRows', {
            min: n(currentPageStart, 'pretty'),
            max: n(currentPageEnd, 'pretty'),
            total: n(total, 'pretty'),
          });
        },
      },
      DatePicker: {
        label(value, selectionMode) {
          if (selectionMode === 'range') {
            const { start, end } = value as any; // TODO: fix type issue

            if (start && end) {
              return `${d(start, 'date')} - ${d(end, 'date')}`;
            }

            if (start) {
              return `${d(start, 'date')} ...`;
            }

            return 'Select dates';
          }

          if (typeof value === 'string') {
            return `${d(value, 'date')}`;
          }

          return 'Select date';
        },
      },
    },
  };

  return config;
});

onBeforeMount(async () => {
  try {
    // Wait auth
    await api.auth.isReady();

    // Handle initial auth state manually
    await handleAuthStateChange({ authenticated: api.auth.isAuthenticated() }, true);

    api.auth.onStateChanged((event) => handleAuthStateChange(event, false));

    // Use previously selected locale (in future can be saved in user profile) or account default locale or browser locale...
    // ...but only if locale is supported by the app.

    let locale = i18nStore.locale || accountStore.locale || window.navigator.language;

    if (!LOCALES.find(({ code }) => code === locale)) {
      locale = DEFAULT_LOCALE;
    }

    setI18nLanguage(locale);
    i18nStore.setLocale(locale);

    // Start unleash client but not in dev mode
    if (!__DEV__) {
      await unleashClient.start();
      await until(flagsReady).toBe(true);
    }

    // TODO: throw error if flagsError or just ignore (report to sentry) and work without flags?

    emitAppReady();

    // Wait initial router navigation
    await router.isReady();

    // Check if user can stay on current route after feature flags update
    unleashClient.on('update', () => {
      reRunCurrentRouteGuards();
    });
  } catch (err) {
    captureException(err);
    error.value = true;
  } finally {
    initializing.value = false;
  }
});

useHead(
  computed(() => ({
    htmlAttrs: {
      lang: i18nStore.locale,
      dir: i18nStore.textDirection,
    },
    titleTemplate: (value) => `${value ? value + ' | ' : ''}Onebeat App`,
  })),
);

function reloadApp() {
  window.location.reload();
}
</script>

<template>
  <ObConfigProvider :config="uiConfig">
    <div v-if="initializing" class="p-8">
      <ObSpinner />
    </div>
    <ObErrorState v-else-if="error" style="min-height: 100vh">
      <template #image>
        <img :src="catError" alt="" />
      </template>
      Internal server error
      <template #details>
        <ObButton @click="reloadApp()">Reload</ObButton>
      </template>
    </ObErrorState>
    <RouterView v-else />
    <ObToasts />
    <ObConfirmDialog />
  </ObConfigProvider>
</template>

<style>
body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  margin: 0;
  min-width: 1400px;
}
</style>
