import { createPinia } from 'pinia';
import type { Pinia } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';

export function createStore(): Pinia {
  const pinia = createPinia();

  pinia.use(
    createPersistedState({
      key: (id) => `onebeat-app:${id}`,
      debug: __DEV__,
    }),
  );

  return pinia;
}

export * from './create-migrating-persisted-state';
export * from './account';
export * from './data';
export * from './app-settings';
export * from './app-settings/store-transfer';
export * from './user';
export * from './i18n';
export * from './system-services-status';
export * from './app-settings/replenishment';
export * from './app-settings/inventory';
export * from './app-settings/sku-chart';
export * from './inventory-inventories-page-store';
export * from './inventory-locations-page-store';
export * from './inventory-skus-page-store';
export * from './replenishment-stores-page-store';
